import { render, staticRenderFns } from "./progress.vue?vue&type=template&id=6e5c1aa6&scoped=true"
import script from "./progress.vue?vue&type=script&lang=js"
export * from "./progress.vue?vue&type=script&lang=js"
import style0 from "./progress.vue?vue&type=style&index=0&id=6e5c1aa6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e5c1aa6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkipToMain: require('/opt/buildhome/repo/components/SkipToMain.vue').default})
