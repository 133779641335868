
export default {
    data() {
        return {
            help: false
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        settings() {
            return this.user.settings;
        },
        url() {
            return process.env.SS_URL;
        },
        logoutUrl() {
            return process.env.LOGOUT_URL;
        },
        voyageUrl() {
            return process.env.VOYAGE_URL;
        },
        launchpadUrl() {
            return process.env.LAUNCHPAD_URL;
        },
        typingUrl() {
            return process.env.TYPING_URL;
        },
        unread() {
            return this.$store.state.notifications.filter(o => o.read === false);
        }
    },
    methods: {
        height() {
            if (process.client) {
                const el = document.getElementById("navbar");
                return el && el.offsetHeight;
            }
        },
        showHelp() {
            this.$refs.help.$refs.modal.show();
        }
    }
};
