
export default {
    props: {
        main: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            show: false
        };
    }
};
