
export default {
    data() {
        return {
            message: "",
            search: "",
            email: "",
            show: false,
            disabled: false
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        sendHelp() {
            const form = {
                email: this.user.email || this.email,
                message: this.message,
                url: this.$route.fullPath,
                impersonating: this.user.person && this.user.person.id,
                currentSection: this.$store.state.currentSection.id
            };
            this.disabled = true;
            this.$axios
                .post(`/support`, form)
                .then(() => {
                    this.message = "";
                    this.show = false;
                    alert("Our support team has received your message and will reply via email as soon as possible!");
                })
                .catch(err => {
                    console.error(err.response);
                    alert(err.response.data);
                })
                .then(() => {
                    this.disabled = false;
                });
        },
        searchKnowledgeBase() {
            const url = "https://support.skillstruck.com/knowledge/kb-search-results?term=" + this.search;
            window.location.href = url;
        }
    }
};
