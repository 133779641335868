export default {
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    methods: {
        setToken(token) {
            window.hsConversationsSettings = {
                identificationEmail: this.user.email,
                identificationToken: token
            };
        },
        async runHubSpotIdentification(open = false) {
            if (this.user.email && !process.env.DEV) {
                const storedToken = this.$getLocal("hubspot_visitor_id_token");
                if (storedToken) {
                    this.setToken(storedToken.value);
                } else {
                    const visitorIdentification = await this.$axios.$get("/user.hubspot");
                    this.setToken(visitorIdentification.token);
                    this.$saveLocal("hubspot_visitor_id_token", {
                        value: visitorIdentification.token,
                        exp: Date.now() + 43200000
                    });
                }

                if (window.HubSpotConversations) {
                    window.HubSpotConversations.widget.load({ widgetOpen: open });
                } else {
                    window.hsConversationsOnReady = [() => window.HubSpotConversations.widget.load({ widgetOpen: open })];
                }
                
                if (window.HubSpotConversations) {
                    window.HubSpotConversations.on("contactAssociated", payload => {
                        console.log(payload.message);
                    });
                }
            }
        },
    }
};