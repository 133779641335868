
export default {
    data() {
        return {
            bannerDismissed: false,
            name: "Educator Portal",
            icon: "home",
            sectionId: this.$store.state.currentSection.id,
            help: false,
            show: false,
            isNext: false,
            isPrev: false
        };
    },
    computed: {
        section: {
            get: function () {
                return this.$store.state.currentSection.id || null;
            },
            set: function (value) {
                this.$store.commit("currentSection", Number(value) || null);
            }
        },
        showBanner() {
            if (localStorage.getItem("bannerDismissed") || Date.now() > new Date("2023-06-10T00:00:00").getTime()) {
                return false;
            }
            return !this.bannerDismissed;
        },
        route() {
            return this.$route.path;
        },
        url() {
            return process.env.SS_URL;
        },
        logoutUrl() {
            return process.env.LOGOUT_URL;
        },
        user() {
            return this.$store.state.user;
        },
        settings() {
            return this.user.settings;
        },
        sections() {
            return this.$store.state.sections;
        },
        currentSection() {
            return this.$store.state.currentSection;
        },
        unread() {
            return this.$store.state.notifications.filter(o => o.read === false);
        },

        pageHasTutorial() {
            return this.$store.state.hasTutorial;
        },
        voyageUrl() {
            return process.env.VOYAGE_URL;
        },
        launchpadUrl() {
            return process.env.LAUNCHPAD_URL;
        },
        typingUrl() {
            return process.env.TYPING_URL;
        },
        flighttrainingUrl() {
            return process.env.SCENES_URL;
        },
        roboticsUrl() {
            return process.env.MICROBIT_URL;
        },
        aiUrl() {
            return process.env.AI_URL;
        },
        studentId() {
            return Number(this.$route.params.id) || null;
        },
        students() {
            if (this.sectionId) {
                return this.$store.state.students.filter(o => o.sections.includes(this.sectionId));
            }
            return this.$store.state.students;
        }
    },
    watch: {
        route(active) {
            this.makeActive(active);
        },
        currentSection(section) {
            this.sectionId = section.id;
            this.getStudentsIds();
        },
        studentId(id) {
            this.getStudentsIds();
        }
    },
    methods: {
        hideBanner() {
            localStorage.setItem("bannerDismissed", `${this.user.id}`);
            // Hide banner and put navbar back to the top of the page.
            this.bannerDismissed = true;
            this.$refs.navInnerContainer.classList.remove("top-60");
            this.$refs.navInnerContainer.classList.add("top-0");
            this.$refs.navOuterContainer.classList.remove("pb-5");
        },
        showHelp() {
            this.$refs.help.$refs.modal.show();
        },
        startTutorial() {
            this.show = false;
            this.$nuxt.$emit("tutorial");
        },
        makeActive(active) {
            const index = active.indexOf("/", 1);
            active = active.substr(0, index > 0 ? index : active.length);
            const el = document.querySelector(`a.dropdown-item[href="${active}"]`);
            if (el) {
                this.name = el.lastElementChild.textContent;
                this.icon = el.firstElementChild.textContent;
            } else {
                this.name = "Educator Portal";
                this.icon = "home";
            }
        },
        populateCheckpoint() {
            const checkpointButton = document.getElementById("checkpointAttempts");
            const quizButton = document.getElementById("questionAttempts");
            checkpointButton.classList.add("active");
            quizButton.classList.remove("active");
            $nuxt.$emit("populateCheckpoint", true);
        },
        populateQuiz() {
            const checkpointButton = document.getElementById("checkpointAttempts");
            const quizButton = document.getElementById("questionAttempts");
            checkpointButton.classList.remove("active");
            quizButton.classList.add("active");
            $nuxt.$emit("populateQuiz", true);
        },
        async resetPerson() {
            if (this.user.person) {
                await this.$axios
                    .$get(`/resetproxy`)
                    .then(() => {
                        this.$store.commit("person", false);
                        this.$store.commit("currentSection", null);
                        this.$store.commit("students", this.$store.state.adminStudents || []);
                        this.$store.commit("sections", this.$store.state.adminSections || []);
                    })
                    .catch(err => {
                        console.error(err);
                        this.$error(err.response.data);
                    });
            }
        },
        launchPlatform(href, platform) {
            $nuxt.$emit("launchPlatform", { url: href, text: platform });
        },
        showInstructions() {
            if (this.$store.state.user.settings.v1 && this.$store.state.user.settings.v2) {
                window.open(
                    "https://support.skillstruck.com/knowledge/how-to-use-skill-strucks-k-12-free-for-classroom-account",
                    "_blank"
                );
            } else if (this.$store.state.user.settings.v2) {
                window.open(
                    "https://support.skillstruck.com/knowledge/how-to-use-skill-strucks-6-12-free-for-classroom-account",
                    "_blank"
                );
            } else {
                window.open(
                    "https://support.skillstruck.com/knowledge/how-to-use-skill-strucks-k-5-free-for-classroom-account",
                    "_blank"
                );
            }
        },
        getStudentsIds() {
            if (this.studentId) {
                const index = this.students.findIndex(obj => obj.id === this.studentId);
                if (this.students.length <= 1) {
                    this.isPrev = false;
                    this.isNext = false;
                } else {
                    this.isPrev = index > 0 && this.students[index - 1].id !== null;
                    this.isNext = index < this.students.length - 1 && this.students[index + 1].id !== null;
                }
            }
        }
    },
    mounted() {
        this.makeActive(this.$route.path);
        // check for notifications here
        if (!localStorage.getItem("notifications")) {
            // this.$store.commit("notifications", []);
        }
        // Put navbar back to the top of the page when banner is dismissed or expired.
        if (localStorage.getItem("bannerDismissed") || Date.now() > new Date("2023-06-10T00:00:00").getTime()) {
            this.$refs.navInnerContainer.classList.remove("top-60");
            this.$refs.navInnerContainer.classList.add("top-0");
            this.$refs.navOuterContainer.classList.remove("pb-5");
        }
        this.getStudentsIds();
    }
};
