import { render, staticRenderFns } from "./default.vue?vue&type=template&id=8c0f2e5c"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkipToMain: require('/opt/buildhome/repo/components/SkipToMain.vue').default,Nav: require('/opt/buildhome/repo/components/Nav.vue').default})
