
import hubspotMixin from "~/mixins/hubspotMixin.js";
export default {
    name: "progress",
    mixins: [hubspotMixin],
    methods: {
        refresh() {
            window.location.reload(true);
        }
    },
    async middleware({ store, redirect, route, $models }) {
        // get students and sections on the server
        if (!store.state.loaded && !store.state.students.length) {
            return $models.Students.get().then(data => {
                store.commit("students", data.students);
                store.commit("sections", data.sections);
                store.commit("schools", data.schools);
                store.commit("hasPastSections", data.hasPastSections);
            });
        }
    },
    computed: {
        accessibe() {
            return this.$store.state.user.accessibe;
        },
        user() {
            return this.$store.state.user;
        },
        storageUrl() {
            return process.env.STORAGE_URL;
        }
    },
    async mounted() {
        window.addEventListener("beforeunload", event => {
            const gaUid = this.$store.state.user.id || false;
            if (gaUid) {
                window.ga("set", "dimension1", gaUid);
            }
            window.ga("set", "page", "/sessionend");
            window.ga("set", "dimesion2", JSON.stringify(new Date().getTime() / 1000));
            window.ga("send", "pageview");
            delete event.returnValue;
        });
        // check for unsupported browser
        if (!["Chrome", "Firefox", "Safari"].includes(this.$ua.browser())) {
            this.$refs.browserWarning.show();
        }
        if (!this.user.sample) {
            // initialize HubSpot live chat
            this.runHubSpotIdentification();
        } else {
            const tutorial = localStorage.getItem("freeTrialVideo");
            if (!tutorial) {
                this.$bvModal.show("free-trial-video");
            }
        }
        const workbox = await window.$workbox;
        if (workbox) {
            workbox.addEventListener("installed", event => {
                if (event.isUpdate) {
                    this.$bvToast.show("update-toast");
                }
            });
        }
    }
};
