export default (context) => {
    const dataStruck = {
        user: {
            id: context.store.state.user.id ? context.store.state.user.id : 'anonymous',
            authorized: context.store.state.user.id ? true : false,
            test: context.store.state.user.test ? true : false
        },
        project: 'Teacher'
    };
    function getDataStruck(toJSON) {
        if (!toJSON) {
            return dataStruck;
        } else {
            return JSON.stringify(dataStruck);
        }
    }

    window.dataLayer = [{
        dataStruck: getDataStruck(true)
    }];

    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-5B7D4V2');
}