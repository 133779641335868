
export default {
    data() {
        return {
            title: "",
            url: ""
        };
    },
    computed: {
        sections() {
            return this.$store.state.sections;
        },
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        handleLaunch(data) {
            this.title = `Launch ${data.text}`;
            this.url = data.url;
            if (this.sections.length) {
                this.$refs.modal.show();
            } else {
                this.launch();
            }
        },
        async launch(sectionId = null) {
            const section = this.sections.find(o => o.id === sectionId);
            // if ((section && section.beta) || (!section && this.user.beta) || this.user.sample) {
            //     this.url = this.url
            //         .replace("elementary.test", "elementary.alpha")
            //         .replace("elementary.skillstruck", "elementary.beta.skillstruck");
            // }
            if ((section && sectionId) || sectionId === null) {
                this.$store.commit("user", { ...this.user, section: section });
                let url = `${process.env.BASE_URL}/selectsection/${sectionId || 0}?next=${this.url}`;
                if (this.user.local) {
                    if (section) {
                        section.beta = true;
                        url += `&section=${encodeURIComponent(JSON.stringify(section))}`;
                    }
                }
                window.open(url);
            } else {
                window.open(this.url);
            }
            this.$refs.modal.hide();
        }
    },
    mounted() {
        this.$nuxt.$on("launchPlatform", this.handleLaunch);
    },
    beforeDestroy() {
        this.$nuxt.$off("launchPlatform", this.handleLaunch);
    }
};
