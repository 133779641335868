
export default {
    data() {
        return {
            name: "Community",
            icon: "home"
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        settings() {
            return this.user.settings;
        },
        voyageUrl() {
            return process.env.VOYAGE_URL;
        },
        launchpadUrl() {
            return process.env.LAUNCHPAD_URL;
        },
        typingUrl() {
            return process.env.TYPING_URL;
        },
        roboticsUrl() {
            return process.env.MICROBIT_URL;
        }
    },
    watch: {
        route(active) {
            this.makeActive(active);
        }
    },
    methods: {
        makeActive(active) {
            const index = active.indexOf("/", 1);
            active = active.substr(0, index > 0 ? index : active.length);
            const el = document.querySelector(`a.dropdown-item[href="${active}"]`);
            if (el) {
                this.name = el.lastElementChild.textContent;
                this.icon = el.firstElementChild.textContent;
            } else {
                this.name = "Community";
                this.icon = "home";
            }
        }
    }
};
