export default ({ app }) => {
    if (!window.Zigpoll) {
        window.Zigpoll = {
            accountId: '5f986868776a5d114f3db279'
        };
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//cdn.zigpoll.com/static/js/main.js';
        document.head.appendChild(script);
    }
    app.router.afterEach((to, from) => {
        if (window.Zigpoll.refresh) {
            window.Zigpoll.refresh();
        }
    })
}