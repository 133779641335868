
export default {
    computed: {
        route() {
            return this.$route;
        },
        crumbs() {
            const crumbs = [
                {
                    text: "Community",
                    to: "/community"
                }
            ];
            const name = this.route.name;
            const items = name.split("-");
            let path = "";
            let id = "Lesson";
            items.forEach(item => {
                let text = item;
                if (item === "community") return;
                if (item === "tutorials") return;
                if (item === "support") {
                    path += "/community";
                }
                if (item === "announcements") {
                    path += "/community";
                }
                if (item === "pd") {
                    text = "Community Plus";
                    path += "/community";
                }
                if (item === "activities") id = "Activity";
                if (item === "resources") path += "/community";
                if (item === "value_of_cs") {
                    text = "The Value of CS";
                }
                if (item === "teacher_courses") {
                    text = "Teacher Training Courses"
                }
                if (item === "id") {
                    if (name === "community-resources-activities-id") {
                        text = this.currentActivity.name;
                    } else if (name.endsWith("-id")) {
                        text = this.currentLesson.name;
                    } else {
                        text = id;
                    }
                }
                path += `/${item}`;
                crumbs.push({
                    text: text ? text.charAt(0).toUpperCase() + text.slice(1) : "Activity",
                    to: `${path}`
                });
            });
            return crumbs;
        },
        currentLesson() {
            const id = this.route.params.id;
            return this.$store.state.community.campusLessons.find(o => o.id == id) || {};
        },
        currentActivity() {
            const id = this.route.params.id;
            return this.$store.state.community.activities.find(o => o.id == id) || {};
        }
    }
};
