
export default {
    data() {
        return {
            visible: false
        };
    },
    computed: {
        notifications() {
            return this.$store.state.notifications;
        }
    },
    methods: {
        hideBody() {
            document.body.classList.add("overflow-hidden");
        },
        showBody() {
            document.body.classList.remove("overflow-hidden");
        },
        getDate(date) {
            return this.$moment(date).fromNow();
        },
        markRead(notification, index) {
            const notifications = [...this.notifications];
            notifications[index] = { ...notification, read: true };
            this.$store.commit("notifications", notifications);
            localStorage.setItem("notifications", true);
        },
        markAllRead() {
            const notifications = JSON.parse(JSON.stringify(this.notifications));
            notifications.forEach(item => {
                item.read = true;
            });
            this.$store.commit("notifications", notifications);
            localStorage.setItem("notifications", true);
        }
    }
};
