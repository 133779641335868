
export default {
    props: ["error"],
    middleware({ app, error }) {
        // insert middleware here
    },
    computed: {
        loginUrl() {
            return `${process.env.LOGIN_URL}?next=${this.$route.path}&test=${Boolean(this.$store.state.user.test)}`;
        }
    },
    beforeMount() {
        if (this.error.statusCode === 401) {
            const path = this.$route.path;
            // redirect immediately
            // window.location.href = `/login?next=${path}`;
        }
    }
};
